/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.slim.min.js
 * - /npm/angular@1.8.2/angular.min.js
 * - /gh/scbd/angular-flex@2.2.2/angular-flex.min.js
 * - /npm/angular-route@1.8.2/angular-route.min.js
 * - /npm/angular-cookies@1.8.2/angular-cookies.min.js
 * - /npm/angular-animate@1.8.2/angular-animate.min.js
 * - /npm/angular-sanitize@1.8.2/angular-sanitize.min.js
 * - /npm/lodash@3.10.1/index.min.js
 * - /npm/moment@2.29.1/min/moment.min.js
 * - /npm/moment-timezone@0.5.43/builds/moment-timezone-with-data-1970-2030.js
 * - /npm/vue@2.7.14/dist/vue.min.js
 * - /npm/popper.js@1.16.0/dist/umd/popper.min.js
 * - /npm/bootstrap@4.1.3/dist/js/bootstrap.min.js
 * - /npm/requirejs@2.3.6/require.min.js
 * - /npm/regenerator-runtime@0.14.1/runtime.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
